import produce, { Draft, enableES5 } from 'immer'
import {
  STORE_USER,
} from './actionTypes'
import { InitialState, UserActions } from './userTypes'
enableES5()

export const initial_state: InitialState = {
  logged_in: null,
}

export default produce((draft: Draft<InitialState>, action: UserActions) => {

  switch (action.type) {
    case STORE_USER: {
      draft.logged_in = action.payload
      break
    }

    default:
      break
  }
}, initial_state)
