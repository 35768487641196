import produce, { Draft, enableES5 } from 'immer'
import { FETCH_ERROR, REDIRECT } from './actionTypes'
import { InitialState, ErrorAction } from './errorTypes'

enableES5()

export const initial_state: InitialState = {
  error: null,
  should_redirect: false,
}

export default produce((draft: Draft<InitialState>, action: ErrorAction) => {
  switch (action.type) {
    case FETCH_ERROR:
      draft.error = action.payload
      break

    case REDIRECT:
      draft.should_redirect = true
      break

    default:
      break
  }
}, initial_state)
