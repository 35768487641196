import React from 'react'
import colors from '../../scss/_colors.scss'

const WhiteLabelRocketLogo = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 792 145" width={175}>
            <g>	
                <g>		
                    <path fill={ colors.black } d="M257,60.5c0,3-0.4,5.7-1.1,8.1c-0.7,2.4-1.8,4.5-3,6.3c-1.3,1.8-2.7,3.4-4.4,4.7c-1.7,1.3-3.4,2.3-5.2,3.2   c-1.8,0.8-3.6,1.4-5.5,1.8c-1.8,0.4-3.6,0.6-5.2,0.6l27.6,23.9h-20.4l-27.6-23.9h-9.5V71.9h29.9c1.7-0.1,3.2-0.5,4.6-1   c1.4-0.6,2.6-1.3,3.6-2.3c1-0.9,1.8-2.1,2.3-3.4c0.5-1.3,0.8-2.9,0.8-4.6v-8.3c0-0.7-0.1-1.3-0.3-1.7c-0.2-0.4-0.4-0.7-0.7-0.9   c-0.3-0.2-0.6-0.3-0.9-0.3c-0.3,0-0.6-0.1-0.9-0.1h-43.6V109h-13.2V42.7c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.4-2.1   c0.6-0.6,1.3-1.1,2.1-1.4c0.8-0.3,1.7-0.5,2.6-0.5H241c3,0,5.4,0.5,7.5,1.6c2,1.1,3.7,2.4,5,4c1.3,1.6,2.2,3.3,2.7,5.2   c0.6,1.9,0.8,3.6,0.8,5.2V60.5z"></path>	
                </g>
                <g>
                    <path fill={ colors.black } d="M346.8,79.5c0,4.4-0.8,8.5-2.3,12.3c-1.5,3.7-3.6,6.9-6.4,9.7c-2.7,2.7-5.9,4.8-9.6,6.3   c-3.7,1.5-7.8,2.3-12.1,2.3h-17.9c-4.4,0-8.4-0.8-12.2-2.3c-3.7-1.5-7-3.6-9.7-6.3c-2.7-2.7-4.8-5.9-6.4-9.7s-2.3-7.8-2.3-12.3   V65.5c0-4.4,0.8-8.5,2.3-12.2c1.5-3.7,3.7-7,6.4-9.7c2.7-2.7,5.9-4.8,9.7-6.3c3.7-1.5,7.8-2.3,12.2-2.3h17.9   c4.4,0,8.4,0.8,12.1,2.3c3.7,1.5,6.9,3.6,9.6,6.3c2.7,2.7,4.8,5.9,6.4,9.7c1.5,3.7,2.3,7.8,2.3,12.2V79.5z M333.6,65.5   c0-2.6-0.4-5-1.2-7.1c-0.8-2.1-2-3.9-3.5-5.4c-1.5-1.5-3.3-2.7-5.4-3.5c-2.1-0.8-4.4-1.2-7-1.2h-17.9c-2.6,0-4.9,0.4-7,1.2   c-2.1,0.8-3.9,2-5.5,3.5c-1.5,1.5-2.7,3.3-3.5,5.4c-0.8,2.1-1.2,4.5-1.2,7.1v13.9c0,2.6,0.4,5,1.2,7.1c0.8,2.1,2,3.9,3.5,5.4   c1.5,1.5,3.3,2.7,5.5,3.5c2.1,0.8,4.5,1.2,7,1.2h17.8c2.6,0,4.9-0.4,7-1.2c2.1-0.8,3.9-2,5.5-3.5c1.5-1.5,2.7-3.3,3.5-5.4   c0.8-2.1,1.2-4.5,1.2-7.1V65.5z"></path>	
                </g>
                <g>		
                    <path fill={ colors.black } d="M425.2,109h-47.7c-1.2,0-2.5-0.1-3.9-0.4c-1.4-0.3-2.8-0.7-4.2-1.3c-1.4-0.6-2.7-1.4-3.9-2.4   c-1.3-1-2.4-2.1-3.3-3.5c-1-1.4-1.7-3-2.3-4.8c-0.6-1.8-0.9-3.9-0.9-6.1V54.6c0-1.2,0.1-2.5,0.4-3.9c0.3-1.4,0.7-2.8,1.3-4.2   c0.6-1.4,1.4-2.7,2.4-3.9c1-1.3,2.2-2.4,3.5-3.3c1.4-1,3-1.7,4.8-2.3c1.8-0.6,3.8-0.9,6.1-0.9h47.7v13.2h-47.7   c-1.7,0-3.1,0.5-4,1.4c-0.9,0.9-1.4,2.3-1.4,4.1v35.7c0,1.7,0.5,3,1.4,3.9c0.9,0.9,2.2,1.4,3.9,1.4h47.7V109z"></path>	
                </g>	
                <g>		
                    <path fill={ colors.black } d="M451.1,109h-13.2V36h13.2V109z M514.2,109h-18.8l-29.8-29.8h-9.3V65.8h9.3L495.4,36h18.8l-37.5,36.5   L514.2,109z"></path>	
                </g>	
                <g>		
                    <path fill={ colors.black } d="M587.9,109h-47.7c-1.8,0-3.8-0.3-6-1c-2.2-0.6-4.2-1.7-6-3.2c-1.8-1.5-3.4-3.4-4.6-5.7   c-1.2-2.4-1.9-5.2-1.9-8.7V42.7c0-0.9,0.2-1.8,0.5-2.6c0.3-0.8,0.8-1.5,1.4-2.1c0.6-0.6,1.3-1.1,2.1-1.4c0.8-0.3,1.7-0.5,2.6-0.5   h59.6v13.2h-53v41.1c0,1.7,0.5,3.1,1.4,4c0.9,0.9,2.3,1.4,4,1.4h47.6V109z M581.9,79.1h-41.8V65.8h41.8V79.1z"></path>	
                </g>	
                <g>		
                    <path fill={ colors.black } d="M666.5,49.2h-29.1V109h-13.2V49.2H595V36h71.6V49.2z"></path>	
                </g>	
                <g>		
                    <g>			
                        <path fill='#ED1D40' d="M734.9,109h-67V84.5c0-1.3,0.1-2.6,0.4-4s0.7-2.8,1.3-4.2c0.6-1.4,1.4-2.7,2.4-4s2.1-2.4,3.5-3.3    c1.4-1,3-1.7,4.8-2.3c1.8-0.6,3.8-0.9,6.1-0.9h29.8c1.8,0,3.1-0.4,4-1.3c0.9-0.9,1.3-2.2,1.3-3.9v-5.8c0-1.8-0.4-3.2-1.3-4.1    c-0.9-0.9-2.2-1.4-3.9-1.4h-42.5V36h42.5c2.2,0,4.3,0.3,6.1,0.9c1.8,0.6,3.4,1.3,4.8,2.3c1.4,1,2.6,2.1,3.5,3.3    c1,1.3,1.8,2.6,2.4,3.9s1.1,2.8,1.4,4.2c0.3,1.4,0.4,2.7,0.4,3.9v6c0,3.4-0.6,6.3-1.9,8.7c-1.2,2.4-2.8,4.3-4.7,5.7    c-1.9,1.5-3.9,2.5-6,3.2c-2.2,0.7-4.2,1-6,1h-29.9c-1.1,0-1.9,0.2-2.6,0.5c-0.6,0.3-1.1,0.7-1.5,1.2c-0.4,0.5-0.6,1.1-0.8,1.7    s-0.3,1.3-0.4,1.9v11.2h53.8V109z"></path>		
                    </g>	
                </g>	
                <g>		
                    <g>			
                        <path fill='#ED1D40' d="M769.7,109h-13.3V49.2h-12V36h25.2V109z"></path>		
                    </g>	
                </g>	
                <g>		
                    <path fill={ colors.black } d="M784.5,36c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5C792,39.4,788.6,36,784.5,36z    M784.5,49.8c-3.5,0-6.3-2.8-6.3-6.3c0-3.5,2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3C790.8,46.9,787.9,49.8,784.5,49.8z"></path>	
                </g>	
                <g>		
                    <path fill={ colors.black } d="M785.6,43.8c0.4-0.1,0.8-0.3,1-0.6c0.2-0.3,0.3-0.7,0.3-1.1c0-0.6-0.2-1-0.6-1.4c-0.4-0.3-0.9-0.5-1.6-0.5   h-2.8v6.1h1.3v-2.3h0.7c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3l1,1.9h1.5l-1-1.8C786.2,44.1,786,43.9,785.6,43.8z    M784.6,42.9h-1.2v-1.7h1.2c0.7,0,1.1,0.3,1.1,0.9C785.7,42.6,785.3,42.9,784.6,42.9z"></path>	
                </g>	
                <g>		
                    <path fill='#ED1D40' d="M31.4,55.5c-5.6,9.7,1.4,21.7,12.5,21.7h27.9h27.9c11.1,0,18.1-12.1,12.5-21.7L98.3,31.4L84.4,7.2   c-5.6-9.7-19.5-9.7-25.1,0L45.3,31.4L31.4,55.5z"></path>	
                </g>	
                <g>		
                    <path fill='#ED1D40' d="M71.8,87.2H60.6c-3.3,0-5.3,3.6-3.7,6.4l5.6,9.7l5.6,9.7c1.6,2.8,5.8,2.8,7.4,0l5.6-9.7l5.6-9.7   c1.6-2.8-0.4-6.4-3.7-6.4H71.8z"></path>	
                </g>	
                <g>		
                    <path fill={ colors.black } d="M103.8,0h-8.6l9.9,17.1c12.3,0.7,22.1,10.9,22.1,23.4v18.1h0v6.7h0v39.1c0,12.9-10.5,23.4-23.4,23.4H39.8   c-12.9,0-23.4-10.5-23.4-23.4V65.3h0v-6.7h0V40.5c0-12.5,9.8-22.7,22.1-23.4L48.4,0h-8.6C17.5,0-0.7,18.2-0.7,40.5v63.9   c0,22.3,18.2,40.5,40.5,40.5h63.9c22.3,0,40.5-18.2,40.5-40.5V40.5C144.3,18.2,126.1,0,103.8,0z"></path>	
                </g>
            </g>
        </svg>
    )
}

export default WhiteLabelRocketLogo