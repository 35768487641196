import React from 'react'
import './loaderToptier.scss'

const WhiteToptierLoaderIcon = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 998.11731 365.92932" height="365.9293212890625" width="998.1173095703125" id="svg2" version="1.1">
  <defs id="defs6">
    <clipPath id="clipPath22" clipPathUnits="userSpaceOnUse">
      <path id="path20" d="M 0,274.447 H 748.588 V 0 H 0 Z" className="svg-elem-1"></path>
    </clipPath>
  </defs>
  <g transform="matrix(1.3333333,0,0,-1.3333333,0,365.92933)" id="g10">
    <g id="g16">
      <g clip-path="url(#clipPath22)" id="g18">
        <g transform="translate(156.6853,230.7822)" id="g70" className="first">
          <path id="path72" style={{fill: 'rgb(20, 83, 177)', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} d="m 0,0 -3.357,-3.364 -3.363,-3.356 -6.727,-6.735 -1.547,-1.539 -6.891,-6.891 h 0.023 c 3.709,0 6.718,-3.002 6.726,-6.711 l 0.142,-66.748 h 30.102 l 0.052,66.739 c 0.003,3.712 3.013,6.72 6.725,6.72 v 0 L 6.734,-6.734 3.371,-3.371 Z" className="svg-elem-2"></path>
        </g>
        <g transform="translate(132.1776,199.54)" id="g74">
          <path id="path76" style={{fill: 'rgb(20, 83, 177)', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} d="m 0,0 -1.468,-1.468 -8.053,-8.053 -35.133,-35.133 -6.927,-6.927 h 14.013 c 3.714,0 6.725,-3.011 6.725,-6.725 v -5.796 H 0 Z" className="svg-elem-3"></path>
        </g>
        <g transform="translate(181.257,199.4901)" id="g78">
          <path id="path80" style={{fill: 'rgb(20, 83, 177)', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} d="m 0,0 v -64.102 h 30.843 v 5.796 c 0,3.714 3.011,6.725 6.725,6.725 h 14.013 l -6.927,6.927 -35.133,35.133 -8.053,8.053 z" className="svg-elem-4"></path>
        </g>
        <g transform="translate(247.4328,125.9243)" id="g82">
          <path id="path84" style={{fill: 'rgb(20, 83, 177)', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} d="M 0,0 -6.371,-6.378 -21.479,-21.479 -46.82,-46.82 v 4.526 c 0,6.45 -5.229,11.679 -11.68,11.679 h -10.17 v -51.517 l -0.121,-0.128 -6.735,6.735 -0.014,0.014 0.029,0.021 -15.187,15.187 -0.014,0.014 -0.021,-0.029 -14.966,-14.958 -0.214,-0.214 0.015,-0.007 -0.015,-0.021 -6.727,-6.728 -0.064,0.065 v 51.566 h -10.17 c -6.45,0 -11.68,-5.229 -11.68,-11.679 v -4.583 l -25.313,25.313 -15.164,15.165 -6.4,6.399 z" className="svg-elem-5"></path>
        </g>
      </g>
    </g>
  </g>
</svg>
  )
}

export default WhiteToptierLoaderIcon