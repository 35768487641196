import produce, { Draft, enableES5 } from 'immer'
import {AUTHENTICATED_USER,AUTHENTICATED_USER_PASSWORD} from './actionType'
import {InitialState,AuthenticateAction} from './authenticateType'
export const initial_state: InitialState = {
    username:'',
    password:''
}

export default produce((draft: Draft<InitialState>, action: AuthenticateAction) => {

    switch (action.type) {

        case AUTHENTICATED_USER: {
            draft.username = action.payload
            break
        }
        case AUTHENTICATED_USER_PASSWORD: {
            draft.password = action.payload
            break
        }
        default:
            break
    }
}, initial_state)