import api from './'

const API = api()
export const basicLogin = async ({ email }) => {
  const request = API.createRequest()
  const url = '/auth/login'

  if (email) {
    return request.post(url, {
      email
    })
  }
}
export const createUser = async ({name, email, alias,country,city,public_user,authenticatedUserName,authenticatedUserPassword,access_level}) => {
  const request = API.createRequest()
  const url = '/user'
  return request.post(url,{ name, email, alias, country, city, access_level, public:public_user, dark_mode: false},{ auth: { username:authenticatedUserName, password:authenticatedUserPassword } })
}
export const getUser=async(userName,password,currentPage)=>{
  // const page=currentPage
  const request = API.createRequest()
  const url = `/user?page=${currentPage}`
  return request.get(url,{auth: { username:userName, password: password }})
}
export const redirectLevels=async({email,authenticatedUserName,authenticatedUserPassword})=>{
  const request=API.createRequest()
  const url='user/login'
  return request.post(url,{email},{ auth: { username:authenticatedUserName, password: authenticatedUserPassword } })
}