import { Dispatch, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { fetchError } from '../error/actions'

import {
  STORE_USER, 
 } from './actionTypes'
import { Store as StoreState } from '../store'
import {userAndAccount} from '../../api/authAPI'
import {  StoreUser } from './userTypes'


export const getUser = (): ThunkAction<
  void,
  StoreState,
  unknown,
  Action<StoreUser>
> => async (dispatch: Dispatch) => {
  try {
    const user = await userAndAccount()
    dispatch({ type: STORE_USER, payload: user.data })

  } catch (err) {
    dispatch(fetchError(err))
  }
}
