import React, { Suspense, useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import store from './redux/store'
import 'inset.js'
import { install } from 'resize-observer'
import Auth from './components/auth'
import * as CONSTS from './consts'
import styles from './app.module.scss'
import Loader from './components/loader'
import 'react-toastify/dist/ReactToastify.css'
import { createBrowserHistory } from 'history'
import Navbar from './components/navbar'

//Lazy loading of the pages
const LandingPage = React.lazy(() => import('./components/landing/landingPage/index'))


export const history = createBrowserHistory()

const App = () => {
  install()
  useEffect(() => {
    document.documentElement.setAttribute('white-label', process.env.BRAND)
    let link:any = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    if(process.env.BRAND){
      link.href = '/faviconWhiteTop.ico'
      document.title = 'Toptier Trader'
    }
    else{
      link.href = '/faviconWhiteRocket.ico'
      document.title = 'Rocket 21'
    }
  }, [])


  return (
    <>
      {/* <ScreenClassProvider> */}
        <Provider store={store}>
          <Router>
            <Navbar/>
            <Switch>
              <Suspense fallback={<Loader />}> 
                  <Route path={CONSTS.LANDING_PATH} exact component={LandingPage} />                
              </Suspense>
            </Switch>
            <Auth>
              <>
          
                <div className={styles.mainPage}>
                  <Suspense fallback={<Loader />}>
                    <Switch>    
                    </Switch>
                  </Suspense>
                </div>
 
              </>
            </Auth>
          </Router>
          <div id="modal"></div>
        </Provider>
      {/* </ScreenClassProvider> */}
    </>
  )
}

export default App
