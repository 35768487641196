import { REDIRECT } from '../error/actionTypes'

interface RedirectToLandingI {
  type: typeof REDIRECT
}

export const redirectToLanding = (): RedirectToLandingI => ({
  type: REDIRECT,
})

