import React from 'react'
import './loaderRocket.scss'

const WhiteRocketLoaderIcon = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 145 145" width="792" height="145">
      <g>		
          <g>		
              <path fill='#ED1D40' className="svg-elem-11" d="M31.4,55.5c-5.6,9.7,1.4,21.7,12.5,21.7h27.9h27.9c11.1,0,18.1-12.1,12.5-21.7L98.3,31.4L84.4,7.2   c-5.6-9.7-19.5-9.7-25.1,0L45.3,31.4L31.4,55.5z"></path>	
          </g>	
          <g>		
              <path fill='#ED1D40' className="svg-elem-22" d="M71.8,87.2H60.6c-3.3,0-5.3,3.6-3.7,6.4l5.6,9.7l5.6,9.7c1.6,2.8,5.8,2.8,7.4,0l5.6-9.7l5.6-9.7   c1.6-2.8-0.4-6.4-3.7-6.4H71.8z"></path>	
          </g>	
          <g>		
              <path fill={ '#000000' } className="svg-elem-33" d="M103.8,0h-8.6l9.9,17.1c12.3,0.7,22.1,10.9,22.1,23.4v18.1h0v6.7h0v39.1c0,12.9-10.5,23.4-23.4,23.4H39.8   c-12.9,0-23.4-10.5-23.4-23.4V65.3h0v-6.7h0V40.5c0-12.5,9.8-22.7,22.1-23.4L48.4,0h-8.6C17.5,0-0.7,18.2-0.7,40.5v63.9   c0,22.3,18.2,40.5,40.5,40.5h63.9c22.3,0,40.5-18.2,40.5-40.5V40.5C144.3,18.2,126.1,0,103.8,0z"></path>	
          </g>
      </g>
  </svg>
  )
}

export default WhiteRocketLoaderIcon