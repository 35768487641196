// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2f4eSwwng7ZAjaZTPrZmWv{width:100%}._2f4eSwwng7ZAjaZTPrZmWv ._331G2JXX0lGMVWYdo_33A4{width:100%;background:#fff;box-shadow:0px 12.7333335876px 25.4666671753px -4.2444443703px #919EAB1F;position:fixed;z-index:1;left:0;top:0}._2f4eSwwng7ZAjaZTPrZmWv ._331G2JXX0lGMVWYdo_33A4 ._1fq4Tqu_pzmZEcH0bkKSaa{min-height:60px;display:flex;align-items:center}._2e6wCm0G88HDzJCRIgbLY4{padding-left:20px}", ""]);
// Exports
exports.locals = {
	"navwrapper": "_2f4eSwwng7ZAjaZTPrZmWv",
	"nav": "_331G2JXX0lGMVWYdo_33A4",
	"logo": "_1fq4Tqu_pzmZEcH0bkKSaa",
	"rocketLogo": "_2e6wCm0G88HDzJCRIgbLY4"
};
module.exports = exports;
