// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#profit-trade{color:#06a86e}#loss-trade{color:#e11}", ""]);
// Exports
exports.locals = {
	"gradientYellow": "linear-gradient(to right, #B3FF20 0%, #D3FE12 37.84%, #EFFD06 77.06%, #F9FD02 100%)",
	"alphaRed": "rgba(255,73,73,.28)",
	"aqua": "#00afd0",
	"brightYellow": "#ffff09",
	"backgroundBlack": "#000",
	"darkGrey": "#1a1723",
	"darkGrey2": "#1f2024",
	"darkerGrey": "#14101e",
	"darkerGreyAlpha": "rgba(20,16,30,.85)",
	"green": "#06a86e",
	"greenDark": "#105e44",
	"greenMain": "#00b377",
	"lightGrey": "gray",
	"lightGrey2": "#454545",
	"lightGrey4": "#404040",
	"offBlack": "#0c0c0e",
	"purple": "#5922fe",
	"redMain": "#f34603",
	"white": "#fff",
	"sellRed": "#f34603",
	"buyGreen": "#13946e",
	"firstColor": "#d3fe12",
	"secondColor": "#effd06",
	"thirdColor": "#f9fd02",
	"statusColourOne": "#b3ff20",
	"statusColourTwo": "#d3fe12",
	"statusColourThree": "#effd06",
	"statusColourFour": "#f9fd02",
	"barWebColor": "#f9fd02",
	"barMobileColor": "#f9fd02",
	"offBackColor": "#eef1f9",
	"onBackColor": "#2952ac",
	"onHandleColor": "#fff",
	"backIconColourOne": "#b3ff20",
	"backIconColourTwo": "#d3fe12",
	"backIconColourThree": "#effd06",
	"backIconColourFour": "#f9fd02",
	"backIconFilledColourOne": "#b3ff20",
	"backIconFilledColourTwo": "#d3fe12",
	"backIconFilledColourThree": "#effd06",
	"backIconFilledColourFour": "#f9fd02",
	"backIconFilledColour": "#000",
	"mobBackIcon": "#fff",
	"levelBarWeb": "#006dfe",
	"levelBarMobile": "#006dfe",
	"logoColourOne": "#b3ff20",
	"logoColourTwo": "#d3fe12",
	"logoColourThree": "#effd06",
	"logoColourFour": "#f9fd02",
	"footerLogo": "gray",
	"donutColourOne": "#b3ff20",
	"donutColourTwo": "#d3fe12",
	"donutColourThree": "#effd06",
	"donutColourFour": "#f9fd02",
	"AssetTransitionUp": "#fff",
	"AssetTransitionDown": "#d45036",
	"AssetPriceUp": "#7bc37e",
	"AssetPriceDown": "#9c0505",
	"openTradesProfit": "green",
	"openTradesLoss": "red",
	"levelsParametersProfit": "#d3fe12",
	"levelsParametersLoss": "#ed5504",
	"tableTextColour": "rgba(255,255,255,.5)",
	"logoTextColour": "#fcfcfc",
	"adminLogoutIconColour": "#fff",
	"gradientWhiteLabelToptier": "linear-gradient(179.92deg, #3A53A7 0.07%, #69CDED 152.62%)",
	"gradientWhiteLabelRocket": "linear-gradient(101.48deg, #df193d -6.99%, #82192c 108.46%)",
	"whiteLabelToptier": "#3a53a7",
	"whiteLabelRocket": "#df193d"
};
module.exports = exports;
