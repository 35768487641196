import { useEffect } from 'react'
import { matchPath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Components
import { Store } from '../../redux/store'
import { CHALLENGES_PATH, CHALLENGE_PATH } from '../../consts'
import { getUser } from '../../redux/user/actions'
import getBearerToken from '../../utils/getBearerToken'
import { getBuildEnvironment } from '../../utils/getEnvironment'

const appURL = window.location.protocol + '//' + window.location.host
const mobile = window.innerWidth < 600

interface AuthProps {
  children
}

const Auth = (props: AuthProps) => {
  var history = useHistory()
  const { children } = props
  const dispatch = useDispatch()
  const user_logged_in = !!useSelector((store: Store) => store.user.logged_in)
  const user = useSelector((store: Store) => store.user.logged_in)
  // const access_level = useSelector((store: Store) => store.user.logged_in?.access_level)
  const should_redirect = useSelector((store: Store) => store.error.should_redirect)

  const addIntercom = () => {
    const scriptAppId = document.createElement('script')
    scriptAppId.textContent =
      '(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic("reattach_activator"); ic("update", w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement("script"); s.type = "text/javascript"; s.async = true; s.src = "https://widget.intercom.io/widget/liy5ydn1"; var x = d.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === "complete") { l(); } else if (w.attachEvent) { w.attachEvent("onload", l); } else { w.addEventListener("load", l, false); } } })();'
    document.body.appendChild(scriptAppId)
  }

  const addGoogleAnalytics = () => {
    // GA script tag for link
    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-Q54DK311W5'
    script.async = true
    document.head.appendChild(script)

    // GA object in script tag
    const gaObject = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', 'G-Q54DK311W5');`
    const scriptGA = document.createElement('script')
    scriptGA.textContent = gaObject
    document.head.appendChild(scriptGA)
  }
  const addIntercomInput = () => {
    const userObject =
      `window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "z0xrz03c",
      name: "` +
      user?.name +
      `", // Full name
      email: "` +
      user?.email +
      `", // Email address
      created_at: "<%= current_user.created_at.to_i %>" // Signup date as a Unix timestamp
    };`
    const scriptIntercom = document.createElement('script')
    scriptIntercom.textContent = userObject
    if (user?.name) {
      document.body.appendChild(scriptIntercom)
      const scriptAppId = document.createElement('script')
      scriptAppId.textContent =
        '(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic("reattach_activator");ic("update",w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement("script");s.type="text/javascript";s.async=true;s.src="https://widget.intercom.io/widget/liy5ydn1";var x=d.getElementsByTagName("script")[0];x.parentNode.insertBefore(s,x);};if(document.readyState==="complete"){l();}else if(w.attachEvent){w.attachEvent("onload",l);}else{w.addEventListener("load",l,false);}}})();'

      document.body.appendChild(scriptAppId)
    }
    if (window.location.pathname.includes('admin')) {
      window.Intercom &&
        window.Intercom('update', {
          hide_default_launcher: true,
        })
    }
  }
  useEffect(() => {
    if (getBuildEnvironment()) {
      if (mobile) {
        if (
          !matchPath(window.location.pathname, CHALLENGE_PATH) &&
          !window.location.pathname.includes('admin')
        ) {
          addIntercomInput()
        }
      } else {
        addIntercomInput()
      }
    }
  }, [user])

  useEffect(() => {
    const cookie_value = getBearerToken()
    const params = new URLSearchParams(window.location.search)
    const query = params.get('bearer')
    const access_token = query || cookie_value

    if (access_token) {
      if (getBuildEnvironment()) {
        if (mobile) {
          if (
            !matchPath(window.location.pathname, CHALLENGE_PATH) &&
            !window.location.pathname.includes('admin')
          ) {
            addIntercom()
          }
        } else {
          addIntercom()
        }

        addGoogleAnalytics()
      }
      localStorage.setItem('token', access_token)
      //document.cookie = `tfxbearer=${access_token} ; path=/`
      dispatch(getUser())
      if (query) {
        document.getElementById('app').style.display = 'flex'
        history.push(CHALLENGES_PATH)
      } else if (access_token) {
        if (window.location.pathname == '/') {
          document.getElementById('app').style.display = 'flex'
          history.push(CHALLENGES_PATH)
        } else {
          document.getElementById('app').style.display = 'flex'
          history.push(window.location.pathname)
        }
      }
    } else {
      if (
        window.location.pathname !== '/' &&
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/signup' &&
        window.location.pathname !== '/invite_registration' &&
        window.location.pathname !== '/privacypolicy' &&
        window.location.pathname !== '/cookies' &&
        window.location.pathname !== '/terms' &&
        window.location.pathname !== '/terms_and_conditions' &&
        window.location.pathname !== '/contract' &&
        window.location.pathname !== '/partner' &&
        window.location.pathname !== '/blog' &&
        window.location.pathname !== '/tutorials'
      ) {
        document.getElementById('app').style.height = '100%'
        window.location.href = appURL
      }
    }
  }, [])

  useEffect(() => {
    if (should_redirect) {
      document.cookie = 'tfxbearer=; expires=0 ; path=/'
      window.location.href = appURL
    }
  }, [should_redirect])

  useEffect(() => {
    if (
      history.location.pathname === '/members/challenges' ||
      history.location.pathname === '/'
    ) {
      localStorage.removeItem('signup_fullName')
      localStorage.removeItem('signup_email')
    }
  }, [])

  if (user_logged_in) {
    return children
  }
  // loader here
  return null
}

export default Auth
