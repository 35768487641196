import React from 'react'
import styles from './index.module.scss'
import Logo from '../../assets/TopTierTrader.png'
import LogoRocket from '../../assets/rocket/white_label_rocket_header'

const Navbar = () => {
  return (
    <div className={styles.navwrapper}>
        <nav className={styles.nav}>
            <div className={styles.logo}>
              {process.env.BRAND ?
                <img src={Logo}/>
                :
                <div className={styles.rocketLogo}>
                  <LogoRocket/>
                </div>
              }
            </div>

        </nav>

    </div>
  )
}

export default Navbar