import React, { useEffect, useState } from 'react'
import './loader.scss'
import getBearerToken from '../../utils/getBearerToken'
import LoaderIconWhiteToptier from '../../assets/white_lable_toptier_loader'
import LoaderIconWhiteRocket from '../../assets/rocket/white_lable_rocket_loader'


const Loader = () => {
  const [login, setLogin] = useState(false)
  const mobile = window.innerWidth < 600

  useEffect(() => {
    if(getBearerToken()){
      setLogin(true)
    }else{
      setLogin(false)
    }
  }, [])

  return (
    <div className={ mobile ? 'sceneMob' : login && (window.location.href.includes('members/challenges') || window.location.href.includes('profile') || window.location.href.includes('leaderboard') || window.location.href.includes('/history'))?'sceneLogin':login && window.location.href.includes('challenge/')?'sceneChallenge':'scene'}>
      {
        process.env.BRAND ?
        <div className='whiteLabelLoader'>
          <LoaderIconWhiteToptier />
        </div>
        :
        <LoaderIconWhiteRocket />
      }
    </div>
  )
}

export default Loader
