const getEnvironment = () => {

    let baseURL = window.location.protocol + '//' + window.location.host + '/api'
    if (window.location.host.includes('localhost'))
        baseURL = 'https://stg-toptier.lv.ls/api'
        
    return baseURL
}

export const getSocketEnvironment = () => {

    let baseURL = 'wss:' + '//' + window.location.host + '/ws'
    if (window.location.host.includes('localhost'))
        baseURL = 'wss://https://stg-toptier.lv.ls/ws'
        
    return baseURL
}

// For google analytics environment which should be on prod only, false - in case of local and stg
export const getBuildEnvironment = () => {
    if ((window.location.host.includes('localhost')) || (window.location.host.includes('stg')))
        return false
    return true
}

export default getEnvironment