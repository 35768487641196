import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'

import { InitialState as ErrorState } from '../error/errorTypes'
import { InitialState as UserState } from '../user/userTypes'
import { InitialState as AuthenticatedUserState } from '../authenticateUser/authenticateType'
import errorReducer from '../error/reducer'
import userReducer from '../user/reducer'
import authenticateReducer from '../authenticateUser/reducer'




const combined = combineReducers({
  error: errorReducer,
  user: userReducer,
  authenticate:authenticateReducer
})

export interface Store {
  error: ErrorState
  user: UserState,
  authenticatedUser:AuthenticatedUserState
}

/*eslint-disable */
const middleware: any[] = [thunk]
/*eslint-enable */

const store = createStore(combined, applyMiddleware(...middleware))

export default store
