import axios from 'axios'

import store from '../redux/store'
import { redirectToLanding } from '../redux/globalActions'
import getBearerToken from '../utils/getBearerToken'
import getEnvironment from '../utils/getEnvironment'

const baseURL = getEnvironment()

export default () => {
  
  const request = axios.create({
    baseURL,
  })
  let REQUEST_AUTHENTICATED = false

  const createRequest = () => {
    if (REQUEST_AUTHENTICATED) return request

    const auth_token = getBearerToken()

    request.defaults.headers.common['Authorization'] = auth_token
    request.defaults.headers['content-type'] = 'application/json'
    request.interceptors.response.use(successfulResponse, errorResponse)
    REQUEST_AUTHENTICATED = true

    return request
  }

  const createMultipartRequest = (token) => {
    const request_test = axios.create({
      baseURL,
    })
    request_test.defaults.headers.common['Authorization'] = token
    request_test.defaults.headers['content-type'] = 'multipart/form-data'
    request_test.interceptors.response.use(successfulResponse, errorResponse)
    REQUEST_AUTHENTICATED = true

    return request_test
  }


  const successfulResponse = (response) => response

  const errorResponse = (error) => {
    if (error.response && ((error.response.status === 404) || (error.response.status === 422))) {
      // window.location.href = '/notfound'
    }
    else if ((error.response && ((error.response.status === 401)))) {
      //store.dispatch(redirectToLanding())
      if (parseInt(localStorage.getItem('serviceStatus')) != 1) {
        localStorage.setItem('serviceStatus', '1')
        localStorage.removeItem('token')
        store.dispatch(redirectToLanding())
      }
    }
    else if ((error.message === 'Network Error') || (error.response.status === 502)) {
      if (parseInt(localStorage.getItem('serviceStatus')) != 1) {
        localStorage.setItem('serviceStatus', '1')
        store.dispatch(redirectToLanding())
      }
    }
    return Promise.reject(error)
  }


  return {
    createRequest,
    createMultipartRequest

  }

}